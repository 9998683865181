import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor.js';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment.js';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat.js';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote.js';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js';
import Code from '@ckeditor/ckeditor5-basic-styles/src/code.js';
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock.js';
import DataFilter from '@ckeditor/ckeditor5-html-support/src/datafilter.js';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js';
import FindAndReplace from '@ckeditor/ckeditor5-find-and-replace/src/findandreplace.js';
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js';
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor.js';
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily.js';
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize.js';
import GeneralHtmlSupport from '@ckeditor/ckeditor5-html-support/src/generalhtmlsupport.js';
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight.js';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline.js';
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed.js';
import Image from '@ckeditor/ckeditor5-image/src/image.js';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption.js';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize.js';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle.js';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar.js';
import Indent from '@ckeditor/ckeditor5-indent/src/indent.js';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock.js';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic.js';
import Link from '@ckeditor/ckeditor5-link/src/link.js';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage.js';
import List from '@ckeditor/ckeditor5-list/src/list.js';
import ListProperties from '@ckeditor/ckeditor5-list/src/listproperties.js';
import Markdown from '@ckeditor/ckeditor5-markdown-gfm/src/markdown.js';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed.js';
import MediaEmbedToolbar from '@ckeditor/ckeditor5-media-embed/src/mediaembedtoolbar.js';
import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak.js';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice.js';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat.js';
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting.js';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough.js';
import Style from '@ckeditor/ckeditor5-style/src/style.js';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript.js';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript.js';
import Table from '@ckeditor/ckeditor5-table/src/table.js';
import TableCaption from '@ckeditor/ckeditor5-table/src/tablecaption.js';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import TableColumnResize from '@ckeditor/ckeditor5-table/src/tablecolumnresize.js';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar.js';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation.js';
import Title from '@ckeditor/ckeditor5-heading/src/title.js';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline.js';
import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount.js';
import './styles.scss'

class Editor extends ClassicEditor {
}

// Plugins to include in the build.
Editor.builtinPlugins = [
	 Alignment,
	// Autoformat,
	// BlockQuote,
	Bold,
	// Code,
	// CodeBlock,
	// DataFilter,
	Essentials,
	FindAndReplace,
	// FontBackgroundColor,
	// FontColor,
	// FontFamily,
	// FontSize,
	GeneralHtmlSupport,
	Heading,
	// Highlight,
	// HorizontalLine,
	// HtmlEmbed,
	// Image,
	// ImageCaption,
	// ImageResize,
	// ImageStyle,
	// ImageToolbar,
	// Indent,
	// IndentBlock,
	Italic,
	Link,
	// LinkImage,
	// List,
	// ListProperties,
	// Markdown,
	// MediaEmbed,
	// MediaEmbedToolbar,
	// PageBreak,
	Paragraph,
	// PasteFromOffice,
	RemoveFormat,
	SourceEditing,
	Strikethrough,
	// Style,
	// Subscript,
	// Superscript,
	// Table,
	// TableCaption,
	// TableCellProperties,
	// TableColumnResize,
	// TableProperties,
	// TableToolbar,
	TextTransformation,
	// Title,
	Underline,
	WordCount
];

// Editor configuration.
Editor.defaultConfig = {
	toolbar: {
		items: [
			'sourceEditing',
			'undo',
			'redo',
			'|',
			'heading',
			// 'style',
			// 'fontFamily',
			// 'fontSize',
			// 'fontColor',
			// 'fontBackgroundColor',
			'|',
			'bold',
			'italic',
			'underline',
			'strikethrough',
			'subscript',
			'superscript',
			'|',
			'alignment',
			/*'|',*/
			// 'bulletedList',
			// 'numberedList',
			// 'outdent',
			// 'indent',
			'|',
			'link',
			// 'mediaEmbed',
			// 'blockQuote',
			// 'insertTable',
			// 'horizontalLine',
			// 'htmlEmbed',
			// 'pageBreak',
			// 'removeFormat',
			// 'codeBlock',
			// 'code',
			'|',
			'findAndReplace',
			'highlight'
		]
	},
	heading: {
		options: [
			{model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
			{model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1'},
			{model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2'},
			{model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3'},
			{model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4'},
			{model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5'}
		]
	},
	language: 'en',
	/*image: {
		toolbar: [
			'imageTextAlternative',
			'toggleImageCaption',
			'imageStyle:inline',
			'imageStyle:block',
			'imageStyle:side',
			'linkImage'
		]
	},*/
	/*table: {
		contentToolbar: [
			'tableColumn',
			'tableRow',
			'mergeTableCells',
			'tableCellProperties',
			'tableProperties'
		]
	},*/
	style: {
		definitions: [
			{
				name: 'Subtitle',
				element: 'p',
				classes: ['subtitle']
			}
		]
	}
};

export default Editor;
